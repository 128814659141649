export const environment = {
  production: true,
  api: $localize`https://api.goal.ge/api/`,
  storage: $localize`//storage.goal.ge`,
  storage_old: $localize`//storage.goal.ge/uploads/`,
  storage_video: $localize`//storage.setantamedia.ge/`,
  storageurlResizerPoster: $localize`//storage.goal.ge/size/timthumb.php?src=/uploads/posts/`,
  domain: $localize`https://goal.ge`,
  livescore_refresh_seconds: 60,
  match_refresh_seconds: 60,
  port: 3004,
  alternate: $localize`https://goal.ge`,
  redisPrefix: 'prod:web-pages:',
};
